import { render, staticRenderFns } from "./Report.vue?vue&type=template&id=b664496a&"
import script from "./Report.vue?vue&type=script&lang=js&"
export * from "./Report.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BIconFileEarmarkPlus, BButton, BIconPencil, BRow, BFormRadio, BFormRadioGroup, BFormGroup, BFormDatepicker, BTr, BTbody, BTableSimple, BIconPencilSquare, BSpinner, BTable, BPagination, BContainer} from 'bootstrap-vue'
    installComponents(component, {BCol, BIconFileEarmarkPlus, BButton, BIconPencil, BRow, BFormRadio, BFormRadioGroup, BFormGroup, BFormDatepicker, BTr, BTbody, BTableSimple, BIconPencilSquare, BSpinner, BTable, BPagination, BContainer})
    

export default component.exports