<template>
  <b-container fluid class="page-content flex-grow-1">
    <b-row class="page-header">
      <b-col>
        <!-- <b-row>
          <b-breadcrumb :items="crumbs"></b-breadcrumb>
        </b-row> -->
        <b-row class="mt-4">
          <b-col>
            <div class="page-title">
              {{ pageTitle }}
            </div>
          </b-col>
          <b-col class="text-right">
            <slot name="download-button">
              <b-button variant="info" class="text-split" @click="debounceExportCsv()" v-if="$route.path.includes('report')">
                <b-icon-file-earmark-plus/><span> DOWNLOAD</span>
              </b-button>
            </slot>
            <b-button variant="info" class="text-split text-uppercase" @click="editFunction()" v-if="newButtonText != null && editFunction != null">
              <b-icon-pencil/> <span>{{ newButtonText }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Section for details above report table -->
    <b-row>
      <slot name="details"></slot>
      <b-col md="12" :lg="$slots['details'] ? 4 : 6" v-if="filterKey">
        <b-row no-gutters>
          <b-form-group :label="`Filter By ${filterKey}`" label-class="font-weight-bold">
            <b-form-radio-group v-model="filterDate">
              <b-form-radio :value="true">Range</b-form-radio>
              <b-form-radio :value="false">All</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-row>
        <b-row v-if="filterDate">
          <b-col>
            <b-form-group label="From:" label-for="date-range-input-start" label-class="font-weight-bold">
              <b-form-datepicker id="date-range-input-start" ref="filterDateStart" today-button reset-button close-button v-model="filterDateRangeStart" :max="filterDateRangeEnd || null" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="To:" label-for="date-range-input-end" label-class="font-weight-bold">
              <b-form-datepicker id="date-range-input-end" ref="filterDateEnd" today-button reset-button close-button v-model="filterDateRangeEnd" :min="filterDateRangeStart || null" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-table-simple small borderless responsive>
          <b-tbody>
            <b-tr>
              <b-button v-if="!loadReport" @click="loadReport = true">Load Report</b-button>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-row>
      <slot name="above-table"></slot>
    </b-row>
    <b-row>
      <b-col>
      <slot name="notes"></slot>
      </b-col>
    </b-row>
    <!-- Bootstrap table -->
    <b-row v-if="loadReport">
      <b-col>
        <b-table :items="items" bordered striped small responsive ref="table" class="report-table" :fields="fields" v-bind="$attrs"
          :busy="busy" sort-direction="desc" :sort-by.sync="sortByLocal" :sort-desc.sync="sortDescLocal"
          :filter="searchText" :per-page="perPageLocal" :current-page="currentPage" v-on="$listeners" @sort-changed="onSort">
          <template v-if="editFunction != null" v-slot:cell(edit)="data">
            <div class="text-center">
              <a role="button" @click="editFunction(data)"><b-icon-pencil-square/></a>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
            <slot :name="slotName" v-bind="slotScope"></slot>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="loadReport">
      <b-col v-if="totalRows != 0">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"/>
      </b-col>
      <div style="position: absolute; margin-right: 15px; right: 0;">
        <slot name="bottom-right"></slot>
      </div>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'StatusReport',
    props: {
      pageTitle: String,
      crumbs: Array,
      fields: {
        // define the columns of the table with key and label
        type: Array,
        required: true
      },
      searchText: {
        type: String,
        default: ''
      },
      sortBy: {
        // bind to column currently being sorted
        type: String,
        default: null
      },
      sortDesc: {
        // bind to column sort direction. overriding b-table default
        type: Boolean,
        default: true
      },
      fetchFunction: {
        // should return a Promise for fetching data which returns an array of items
        // that can be directly inserted into the table.
        // the items should match with provided fields
        type: Function,
        required: true
      },
      editFunction: Function,
      newButtonText: String,
      filterKey: String, // label inserted into "Filter By ____" for date filtering if applicable. Filter date range is disabled if not present
      includeRetired: {
        type: Boolean,
        default: false
      },
    },
    data () {
      return {
        items: [],
        gate: null,
        event: null,
        busy: false,
        sortByLocal: this.sortBy,
        sortDescLocal: this.sortDesc,
        debounce: null,
        loadReport: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 20,
        perPageLocal: 0, // this must be 0 if using server-side pagination. else set to perPage
      }
    },
    computed: {
      filterDateRangeStart: {
        get () {
          if(this.$store.state.filterDateRangeStart === '' || this.$store.state.filterDateRangeStart === null){
            // get 5 days before
            // TODO make this a setting somewhere in account
            var tempDate = new Date();
            tempDate.setDate(tempDate.getDate() - 5);
            var tempDateStr = tempDate.toISOString().slice(0, 10);

            this.$store.commit('setFilterDateRangeStart', tempDateStr)
          }
          return this.$store.state.filterDateRangeStart
        },
        set (val) {
          this.$store.commit('setFilterDateRangeStart', val)
        }
      },
      filterDateRangeEnd: {
        get () {
          if(this.$store.state.filterDateRangeEnd === '' || this.$store.state.filterDateRangeEnd === null){
            // today
            // TODO make this a setting somewhere in account
            var todayDate = new Date().toISOString().slice(0, 10);
            todayDate = todayDate + ' 23:59:59'
            this.$store.commit('setFilterDateRangeEnd', todayDate);
          }
          return this.$store.state.filterDateRangeEnd
        },
        set (val) {
          val = val + ' 23:59:59'
          this.$store.commit('setFilterDateRangeEnd', val)
        }
      },
      filterDate: {
        get () {
          return this.$store.state.filterDate
        },
        set (val) {
          this.$store.commit('setFilterDate', val)
        }
      },
    },
    watch: {
      loadReport: 'populateTable',
      // '$route': {
      //   handler: 'populateTable',
      //   immediate: true
      // },
      currentPage: function () {
        if (this.perPageLocal == 0) {
          // this.perPageLocal > 0 means this.items is NOT server-paginated,
          // therefore no need to repopulate table when changing page
          this.populateTable()
        }
      },
      filterDate: function () {
        this.loadReport = false
      },
      filterDateRangeStart: function () {
        this.loadReport = false
      },
      filterDateRangeEnd: function () {
        this.loadReport = false
      },
      includeRetired: function () {
        this.loadReport = false
      },
      $route: function () {
        this.loadReport = false

        var tempDate = new Date();
        tempDate.setDate(tempDate.getDate() - 5);
        var tempDateStr = tempDate.toISOString().slice(0, 10);

        this.$store.commit('setFilterDateRangeStart', tempDateStr)

        var todayDate = new Date().toISOString().slice(0, 10);
        todayDate = todayDate + ' 23:59:59'
        this.$store.commit('setFilterDateRangeEnd', todayDate);
      }
    },
    methods: {
      /**
       * This populates the table with data from the provided fetchfunction
       */
      populateTable () {
        this.busy = true
        if (this.editFunction != null) {
          this.fields.push({ key: 'edit', label: '' })
        }

        let args = ['get_all=true'] // TODO: transitioning from server-side to client-side pagination
        if (this.currentPage != null) {
          args.push(`page=${this.currentPage}`)
        }
        if (this.sortByLocal != null) {
          args.push(`sort=${this.sortByLocal}`)
          if (this.sortDescLocal != null) {
            args.push(`desc=${this.sortDescLocal}`)
          }
        }
        if (this.filterDate && this.filterKey) {
          if (this.filterDateRangeStart)
            args.push(`start=${this.filterDateRangeStart}`)
          if (this.filterDateRangeEnd)
            args.push(`end=${this.filterDateRangeEnd}`)
        }

        this.fetchFunction(args)
          .then(data => {
            if (data.results != null && Array.isArray(data.results)){
              // results are paginated
              this.items = data.results
              this.totalRows = data.count
            } else {
              this.items = data
              this.totalRows = data.length
              this.perPageLocal = this.perPage
            }
            this.busy = false
            this.$emit('items', this.items)
          })
          .catch(e => {
            console.log('Report populateTable', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
            this.busy = false
          })
      },
      onCreate (item, model) {
        this.items.unshift(item)
        this.setAlert({ variant: 'success', message: `${model != null ? model : 'Record'} created` })
      },
      onSave (data, model) {
        this.$set(this.items, data.index, data.item)
        this.setAlert({ variant: 'success', message: `${model != null ? model : 'Record'} updated` })
      },
      onDelete (index, model) {
        this.items.splice(index, 1)
        this.setAlert({ variant: 'success', message: `${model != null ? model : 'Record'} deleted` })
      },
      onRestore (index, model) {
        this.items.splice(index, 1)
        this.setAlert({ variant: 'success', message: `${model != null ? model : 'Record'} restored` })
      },
      onSort () {
        if (this.totalRows > 0 && this.perPageLocal == 0 && this.sortByLocal in this.items[0]) {
          // this.perPageLocal > 0 means this.items is NOT server-side paginated
          // this.sortByLocal could be a formatted field, so don't use it to sort
          // HOWEVER this doesn't handle custom serializer fields, so this will result in some errors
          // TODO: if we decide to do only client-side pagination, remove server-side pagination handlers
          this.populateTable()
        }
      },
      debounceExportCsv () {
        const later = () => {
          this.debounce = null
          this.exportCsv()
        }
        clearTimeout(this.debounce)
        this.debounce = setTimeout(later, 250)
      },
      /**
       * This exports the report as a csv.
       */
      exportCsv () {
        //TODO not sure how this function will behave with 0 entries

        //check if route is from tageventreport

        let csv = '';

        let filtered = this.fields.filter(e => !e.csvExcluded)
        let fieldNames = filtered.map(e => e.label ? e.label : e.key)

        let arr = this.items.map(item => filtered.map(field => this.byString(item, field)).join(','))

        // adding fields to the main table
        // for(let index = 0; index < arr.length; index++){
        //   arr[index] += ',' + this.items[index].manual_count
        // }

        //construct CSV string
        csv += fieldNames.join(',') + '\n'
        for(let el of arr){
          csv += el + '\n'
        }

        // console.log(csv)
        // window.open('data:text/csv;charset=utf-8,' + encodeURI(csv), '_blank', '')
        let link = document.createElement('a')
        link.download = `${this.pageTitle.replace(/ /g, '_')}-export-${new Date().toISOString()}.csv`
        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        link.click()
        link.remove()
      },
      byString (item, field, separator='.') {
        let properties = field.key.includes(separator) ? field.key.split(separator) : [field.key]
        //let value = properties.reduce((prev, curr) => prev && prev[curr], item)
        let value = null
        for (let prop of properties)
        {
          if (value == null) // TODO: Make this work for all array indexes and all depths of keys
          {
            value = prop.includes('[0]') ? item[prop.substring(0, prop.indexOf('['))][0] : item[prop]
          } else
          {
            value = value[prop]
          }
        }
        // console.log(item, field, value)

        if (typeof field.formatter === 'function') {
          let formatted = field.formatter(value, field.key, item)
          // console.log(formatted)
          if (formatted != null) {
            let formatted_str = formatted.toString() // Force field to be String because only strings have .replace()
            if(typeof formatted === 'object') {// if field is an object separate with business name
              formatted_str = '' //TODO will not work in future if we use objects for other items
              for(let ele of formatted){
                formatted_str += ele.business_name + ' '
              }
            }
            return formatted_str ? formatted_str.replace(/,/g, '') : null // call formatter and remove commas
          }
          return ''
        } else {
          return value
        }
      },
    }
  }
</script>
